/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnswerDetailDto } from '../models/AnswerDetailDto';
import type { AnswerDto } from '../models/AnswerDto';
import type { AnswerListDto } from '../models/AnswerListDto';
import type { AnswerReviewDto } from '../models/AnswerReviewDto';
import type { CompleteTenderRequest } from '../models/CompleteTenderRequest';
import type { CreateCommentRequest } from '../models/CreateCommentRequest';
import type { MarkAsCompleteRequest } from '../models/MarkAsCompleteRequest';
import type { RemoveAnswerAttachment } from '../models/RemoveAnswerAttachment';
import type { StringStringValuesKeyValuePair } from '../models/StringStringValuesKeyValuePair';
import type { UpVoteAnswerRequest } from '../models/UpVoteAnswerRequest';
import type { UpVoteCommentRequest } from '../models/UpVoteCommentRequest';
import type { WriteAnswerReviewRequest } from '../models/WriteAnswerReviewRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnswersService {

    /**
     * @param formData 
     * @returns AnswerDto Success
     * @throws ApiError
     */
    public static postApiV1Answers(
formData?: {
form?: Array<StringStringValuesKeyValuePair>;
},
): CancelablePromise<AnswerDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param questionId 
     * @param lastId 
     * @returns AnswerListDto Success
     * @throws ApiError
     */
    public static getApiV1Answers(
questionId?: string,
lastId?: string,
): CancelablePromise<AnswerListDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Answers',
            query: {
                'questionId': questionId,
                'lastId': lastId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AnswersRemoveAttachment(
requestBody?: RemoveAnswerAttachment,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers/RemoveAttachment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AnswersUploadAttachment(
formData?: {
form?: Array<StringStringValuesKeyValuePair>;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers/UploadAttachment',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1AnswersGetMyAnswers(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Answers/GetMyAnswers',
        });
    }

    /**
     * @param answerId 
     * @returns AnswerDetailDto Success
     * @throws ApiError
     */
    public static getApiV1AnswersGetAnswerDetail(
answerId?: string,
): CancelablePromise<AnswerDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Answers/GetAnswerDetail',
            query: {
                'answerId': answerId,
            },
        });
    }

    /**
     * @param questionId 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1AnswersGetUserAnswerForQuestion(
questionId?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Answers/GetUserAnswerForQuestion',
            query: {
                'questionId': questionId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AnswersUpVoteAnswer(
requestBody?: UpVoteAnswerRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers/UpVoteAnswer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AnswersDownVoteAnswer(
requestBody?: UpVoteAnswerRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers/DownVoteAnswer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AnswersUpVoteAnswerComment(
requestBody?: UpVoteCommentRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers/UpVoteAnswerComment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AnswersDownVoteAnswerComment(
requestBody?: UpVoteCommentRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers/DownVoteAnswerComment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param answerId 
     * @param lastId 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1AnswersGetAnswerComments(
answerId?: string,
lastId?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Answers/GetAnswerComments',
            query: {
                'answerId': answerId,
                'lastId': lastId,
            },
        });
    }

    /**
     * @param buyerId 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1AnswersGetMyUnPaidAnswersForBuyer(
buyerId?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Answers/GetMyUnPaidAnswersForBuyer',
            query: {
                'buyerId': buyerId,
            },
        });
    }

    /**
     * @param buyerId 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1AnswersGetMyAvailableTendersForBuyer(
buyerId?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Answers/GetMyAvailableTendersForBuyer',
            query: {
                'buyerId': buyerId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AnswersMarkAsComplete(
requestBody?: MarkAsCompleteRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers/MarkAsComplete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AnswersCompleteTender(
requestBody?: CompleteTenderRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers/CompleteTender',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiV1AnswersRemoveComment(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Answers/RemoveComment/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiV1Answers(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Answers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param answerId 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1AnswersGetAnswerReviews(
answerId?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Answers/GetAnswerReviews',
            query: {
                'answerId': answerId,
            },
        });
    }

    /**
     * @param userId 
     * @returns AnswerReviewDto Success
     * @throws ApiError
     */
    public static getApiV1AnswersGetReviewsForProfile(
userId?: string,
): CancelablePromise<Array<AnswerReviewDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Answers/GetReviewsForProfile',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AnswersWriteAnswerReview(
requestBody?: WriteAnswerReviewRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers/WriteAnswerReview',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AnswersCreateComment(
requestBody?: CreateCommentRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Answers/CreateComment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}