import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Alert,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Rating, Snackbar,
    Stack,
    TextField,
    ToggleButton, useMediaQuery
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {ChangeEvent, useCallback, useContext, useEffect, useRef, useState} from "react";
import CircularProgress from '@mui/material/CircularProgress';

import {useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import {PrimaryTheme} from '../components/themes';

// import tinymce from "tinymce/tinymce";
// import "@wiris/mathtype-tinymce4/plugin.min";
import {Editor} from "@tinymce/tinymce-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ThemedStyledToggleButtonGroup} from "../components/StylizedComponents";
import {styled} from "@mui/material/styles";

import {WithContext as ReactTags} from 'react-tag-input';
import '../components/tagstyle.css';
import DrawingDialog from "../components/DrawingDialog";
import FileDropzone from "../components/FileDropzone";

import ImageEditorDialog from "../components/ImageEditorDialog";
import PhotoCameraDialog from "../components/PhotoCameraDialog";
import {
    OpenAPI,
    QuestionDto,
    QuestionsService,
    StringStringValuesKeyValuePair, TopicDto,
    TopicsService
} from "../services/autogen";
import {request as __request} from "../services/autogen/core/request";
import {green} from "@mui/material/colors";
import {strip} from "../helpers/DomManipulator";
import {useAuth} from "../contexts/AuthContext";
import MathKeyboardDialog from "../components/MathKeyboardDialog";
import {MathJaxBaseContext} from "better-react-mathjax";
import {
    create_file_picker_callback,
    desktopEditorAttributes,
    mobileEditorAttributes,
    TinyMceEditorWrapper
} from "./tinyMceCommon";

let theme = PrimaryTheme;

const suggestions = ["Maths", "Physics", "Chemistry", "Biology", "General Papers"].map(item => {
    return {
        id: item,
        text: item
    };
});

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#f5b63a',
    },
    '& .MuiRating-iconHover': {
        color: '#eac179',
    },
});

const labels: { [index: string]: string } = {
    1: '1 - 10 Coins',
    2: '11 - 20 Coins',
    3: '21 - 50 Coins',
    4: '51 - 100 Coins',
    5: '> 100 Coins',
};

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export function PostQuestion() {
    let {questionId} = useParams();
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    let [question, setQuestion] = useState<QuestionDto>();
    
    const [loading, setLoading] = React.useState(false);

    const [tags, setTags] = useState([{id:'General', text:'General'}]);
    const [initValue, setInitValue] = useState<string>("<p></p>");
    let [title, setTitle] = useState<string>("");
    let navigate = useNavigate();

    //const [topic, setTopic] = useState("Maths");

    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;

    const {currentUser, setCurrentUser} = useAuth();

    const [topicList, setTopicList] = useState<Array<TopicDto>| null>(null);

    const [selectedTopics, setSelectedTopics] = React.useState(() => ['Maths']);

    const [topic, setTopic] = React.useState<string[] | undefined>();

    useEffect(()=> {
        if(currentUser){
            TopicsService.getApiV1Topics()
                .then(val => {
                    var selectedTopics = val.filter(function(x) { return (["Maths", "Physics", "Chemistry", "Science"].indexOf(x.topicName || "") != -1) });
                    setTopicList(selectedTopics);
                    //setLastAlertId(val?.data?[val.data.length-1].id)
                });
        }
    },[currentUser]);
    
    type tagProps = {
        id: string,
        text: string,
    };

    const handleDelete = (i : number) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag : tagProps) => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag : tagProps, currPos: number, newPos: number) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleTagClick = (index: number) => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    
    
    const editorRef = useRef<any | null>(null);
    
    const insertImage = useCallback((source : any) => {
        console.log(editorRef.current);

        if (!!editorRef.current) {
            editorRef.current.insertContent('<img alt="canvas" src="' + source + '" style="max-width:100%" />');
        }
    }, []);

    const handleSnackBarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    };

    const handleSelectedTopicChanges = (
        event: React.MouseEvent<HTMLElement>,
        newTopics: string[],
    ) => {
        setSelectedTopics(newTopics);
        console.log("new topics: " + newTopics);
        setTopic(newTopics);
        console.log("selected topics: " + selectedTopics);
    };

    const [value, setValue] = React.useState<number | null>(1);
    const [hover, setHover] = React.useState(-1);
    const [active, setActive] = React.useState<boolean | undefined>(false);

    const handleCheckBoxChanges = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            setValue(-1);
            setActive(true);
        }
        else {
            setValue(1);
            setActive(false);
        }
    };

    function showErrorMessage(message: string) {
        setErrorMessage(message);
        setSnackBarOpen(true);
    }

    async function handlePublish() {
        
        // check that all the important entries are there
        if (title=="") {
            showErrorMessage("Title is empty");
        }

        if (!topic) {
            showErrorMessage("Topic is not selected");
            console.log("topic when publish button is pressed: " + topic);
        }

        let formdata:Record<string,any> = {};
        formdata["title"] = title;
        let content = editorRef.current.getContent();
        formdata["description"] = content;
        formdata["questionType"] = "free";
        //formdata["topic"]  = "5d808d785b1fe80e988f4c88";
        formdata["topic"]  = topic;
        formdata["allowTender"] = "false";
        formdata["expiry"] = 1;
        formdata["minBudget"] =0;
        formdata["isPrivate"] =false ;
        if (content != null && content.trim() !== "") {
            formdata["descriptionText"] = strip(content);
        }
        
        if(questionId){
            formdata["id"] = questionId;
        }
        formdata["source"] = "peakqitt";
        
        
        // @ts-ignore
        try {
            setLoading(true);

            let response: QuestionDto;
            if (questionId){ // means it is an Edit of existing question
                response = await __request(OpenAPI, {
                    method: 'POST',
                    url: '/api/v1/Questions/EditQuestion',
                    formData: formdata,
                    mediaType: 'multipart/form-data',
                });
            } else{
                response = await __request(OpenAPI, {
                    method: 'POST',
                    url: '/api/v1/Questions',
                    formData: formdata,
                    mediaType: 'multipart/form-data',
                });                 
            }
            navigate(`/questions/${response.id}/${response.slugUrl}`);
        } catch(e: any){
            setLoading(false);
            console.log(e);
            let message = e.body?.message;
            if (message){
                showErrorMessage(message);
            }
        }
    }

    function setTitleFromTextField(event: ChangeEvent<HTMLInputElement>) {
        setTitle(event.target.value); 
    }
    
    let windowWidth = window.outerWidth;

    useEffect(()=> {
        if(questionId){
            QuestionsService.getApiV1Questions(questionId!)
                .then(val => {
                    setQuestion(val);
                    setTitle(val?.title!);
                    setInitValue(val?.description!);
                });
        }
    },[]);

    const insertEquation = useCallback((dataUrlImage, latex) => {
        console.log(editorRef.current);

        if (!!editorRef.current) {
            editorRef.current.insertContent(`<img data-latex="${latex}" src="${dataUrlImage}"/>`);
        }
    }, []);

    const titleRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (titleRef.current != null) {
            titleRef.current.focus();
        }
    }, [titleRef])
    
    
    return (
        <Box sx={{ width: '100%',  display:'flex', justifyContent:'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: mobile ? window.outerWidth : '720px', justifyContent:'center', marginTop: desktop ? '75px' :'75px' }}>
                <Box sx={{flexGrow: 1, height:'100%', display:'flex', justifyContent:'center', maxWidth: window.outerWidth}}>
                    <Box sx={{width:'90%', display:'flex', flexDirection:'column' }}>
                        <TextField ref={titleRef} id="outlined-basic"
                                   helperText="Please enter your question headline"
                                   placeholder="Title" 
                                   label={""}
                                   variant="outlined" 
                                   fullWidth
                                   size="small"
                                   sx={{
                                       marginTop:'15px',
                                       input: {
                                           backgroundColor: theme.palette.common.white
                                       }
                                   }}
                                   onChange={setTitleFromTextField}
                        />
                        <Box sx={{ marginTop: '10px', display:'flex', justifyContent: 'space-between'}}>
                            <Typography variant="subtitle1" component="span" align="center" sx={{display: 'block', fontWeight: '600', color: theme.palette.primary.main}}>
                                Description
                            </Typography>
                            <Box>
                                <MathKeyboardDialog insertEquation={insertEquation}/>
                                <DrawingDialog insertImage={insertImage}/>
                                <ImageEditorDialog insertImage={insertImage}/>
                                <PhotoCameraDialog insertImage={insertImage}/>
                            </Box>
                        </Box>
                        {desktop &&
                            <Box sx={{height:'450px'}}>
                                <TinyMceEditorWrapper
                                    editorRef={editorRef}
                                    initValue={initValue}
                                    placeholder="Question Description ..."
                                    otherAttributes={desktopEditorAttributes}
                                />
                                <Typography component="span" align="center" sx={{
                                    marginTop: '5px',
                                    display: 'block',
                                    fontSize: '0.65rem',
                                }}>
                                    Please try to provide your attempts on the question and any workings of earlier parts of the questions
                                </Typography>

                                <Typography component="span" align="center" sx={{
                                    marginTop: '0px',
                                    display: 'block',
                                    fontSize: '0.65rem',
                                }}>
                                    [ e.g provide your workings for part (a) if you need help for part (b) and (c) ]
                                </Typography>
                                
                                <Typography component="span" align="center" sx={{
                                    marginTop: '5px',
                                    display: 'block',
                                    fontSize: '0.6rem',
                                    color: '#ba000d'
                                }}>
                                    Please restrict to only one question per post
                                </Typography>
                            </Box>
                        }
                        {mobile &&
                            <Box sx={{/*height:'500px'*/}}>
                                <TinyMceEditorWrapper
                                    editorRef={editorRef}
                                    initValue={initValue}
                                    placeholder="Question Description ..."
                                    otherAttributes={mobileEditorAttributes}
                                />
                                <Typography component="span" align="center" sx={{
                                    marginTop: '5px',
                                    display: 'block',
                                    fontSize: '0.65rem',
                                }}>
                                    Please try to provide your attempts on the question and any workings of earlier parts of the questions
                                </Typography>

                                <Typography component="span" align="center" sx={{
                                    marginTop: '0px',
                                    display: 'block',
                                    fontSize: '0.65rem',
                                }}>
                                    [ e.g provide your workings for part (a) if you need help for part (b) and (c) ]
                                </Typography>
                                <Typography component="span" align="center" sx={{
                                    marginTop: '5px',
                                    display: 'block',
                                    fontSize: '0.6rem',
                                    color: '#ba000d'
                                }}>
                                    Please restrict to only one question per post
                                </Typography>
                            </Box>
                        }
                        { /* TODO: disable upload file for now
                        <FileDropzone/>
                        */}
                        
                        { /* TODO: disable tag for now
                        <Box sx={{ marginTop: '10px', display:'flex'}}>
                            <Typography variant="subtitle1" component="span" align="center" sx={{display: 'block', fontWeight: '600', color: theme.palette.primary.main}}>
                                Tags
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', width: '100%'}}>
                            <ReactTags
                                tags={tags}
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                handleDrag={handleDrag}
                                handleTagClick={handleTagClick}
                                inputFieldPosition="inline"
                                autocomplete
                                autofocus={false}
                            />
                        </Box>
                        */}
                        {desktop &&
                            
                            <Box sx={{display: 'flex', flexDirection: "column", marginTop: '75px'}}>
                                <ThemedStyledToggleButtonGroup id="selectedTopicsButtonGroup"
                                    color="primary"
                                    value={selectedTopics}
                                    onChange={handleSelectedTopicChanges}
                                    size="small"
                                    exclusive
                                    >
                                    {topicList &&
                                        topicList.map(topic => (
                                        <ToggleButton sx={{
                                        borderRadius: 1,
                                        margin: 1,
                                        fontSize: desktop ? '0.85rem' : '0.55rem',
                                        padding: desktop ? '7px' : '1px'
                                        }} key={topic.id || "0"} id={topic.id || "0"}
                                            value={topic.id || "0"}>{topic.topicName}</ToggleButton>
                                            ))
                                    }
                                </ThemedStyledToggleButtonGroup>
                                <Typography component="span" align="center" sx={{
                                    display: 'block',
                                    fontSize: '0.6rem',
                                    color: theme.palette.primary.main
                                }}>
                                    Please select your topic (mandatory)
                                </Typography>
                            </Box>
                       
                                
                            /*
                            <Accordion sx={{background: PrimaryTheme.palette.background.default}} defaultExpanded={true}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography>Settings</Typography>
                                </AccordionSummary>
                               
                                <AccordionDetails>
                                    <Stack spacing={2}>
                                        <Box sx={{display: 'flex', flexDirection: "column",}}>
                                            <Typography variant="subtitle1" component="span" align="left" sx={{
                                                display: 'block',
                                                fontWeight: '600',
                                                color: theme.palette.primary.main
                                            }}>
                                                Topics
                                            </Typography>
                                            <ThemedStyledToggleButtonGroup id="selectedTopicsButtonGroup"
                                                                           color="primary"
                                                                           value={selectedTopics}
                                                                           onChange={handleSelectedTopicChanges}
                                                                           size="small"
                                                                           exclusive
                                            >
                                                {topicList &&
                                                topicList.map(topic => (
                                                    <ToggleButton sx={{
                                                        borderRadius: 1,
                                                        margin: 1,
                                                        fontSize: desktop ? '0.85rem' : '0.55rem',
                                                        padding: desktop ? '7px' : '1px'
                                                    }} key={topic.id || "0"} id={topic.id || "0"}
                                                                  value={topic.id || "0"}>{topic.topicName}</ToggleButton>
                                                ))
                                                }
                                            </ThemedStyledToggleButtonGroup>
                                        </Box>
                                        <Divider/>
                                        {
                                        <Box sx={{display:'flex', flexDirection:"column", }}>
                                            <Typography variant="subtitle1" component="span" align="left" sx={{display: 'block', fontWeight: '600', color: theme.palette.primary.main}}>
                                                Budget
                                            </Typography>
                                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                                <StyledRating
                                                    name="customized-color"
                                                    value={value}
                                                    getLabelText={getLabelText}
                                                    precision={1}
                                                    icon={<MonetizationOnIcon fontSize={desktop ? "inherit" :"small"} />}
                                                    emptyIcon={<MonetizationOnOutlinedIcon fontSize={desktop ? "inherit" :"small"}  />}
                                                    onChange={(event, newValue) => {
                                                        setValue(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    disabled={active}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 2,  fontSize: desktop ? '0.85rem' : '0.8rem'}}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </Box>
                                            <FormGroup sx={{alignItems: 'center'}}>
                                                <FormControlLabel control={<Checkbox onChange={handleCheckBoxChanges} />} 
                                                                  label={<Typography sx={{fontSize: desktop ? '0.85rem' : '0.8rem'}}>I don't want to pay </Typography>}  />
                                            </FormGroup>
                                        </Box>
                                        }
                                    </Stack>
    
                                </AccordionDetails>
                            </Accordion>
                            */
                        }
                        {mobile &&
                            <Box sx={{display: 'flex', flexDirection: "column", marginTop: '75px'}}>
                                <Typography variant="subtitle1" component="span" align="left" sx={{
                                    display: 'block',
                                    fontWeight: '600',
                                    color: theme.palette.primary.main
                                }}>
                                    Topics
                                </Typography>
                                <ThemedStyledToggleButtonGroup id="selectedTopicsButtonGroup"
                                                               color="primary"
                                                               value={selectedTopics}
                                                               onChange={handleSelectedTopicChanges}
                                                               size="medium"
                                                               exclusive
                                >
                                    {topicList &&
                                    /*
                                    ["Maths", "Physics", "Chemistry", "Biology", "Social Studies"].map(topic => (
                                        <ToggleButton sx={{borderRadius:1, margin:1, fontSize:desktop?'0.85rem':'0.55rem', padding:desktop?'7px':'1px' }} key={topic} id={topic} value={topic}>{topic}</ToggleButton>
                                    ))*/
                                    topicList.map(topic => (
                                        <ToggleButton sx={{
                                            borderRadius: 1,
                                            margin: 1,
                                            fontSize: desktop ? '0.85rem' : '0.65rem',
                                            padding: desktop ? '7px' : '1px'
                                        }} key={topic.id || "0"} id={topic.id || "0"}
                                                      value={topic.id || "0"}>{topic.topicName}</ToggleButton>
                                    ))
                                    }
                                </ThemedStyledToggleButtonGroup>
                            </Box>
                        }
                        <Box sx={{position: 'relative', marginTop: '20px'}}> 
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                            <Button
                                fullWidth
                                disabled={loading}
                                variant="contained" color="success" onClick={handlePublish}>
                                Publish
                            </Button>
                        </Box>
                        
                        {/*<Box sx={{height: '20px'}}/> */}
                    </Box>
                </Box>
                
            </Box>
            <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleSnackBarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackBarClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
        
    );
}