/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppleUser } from '../models/AppleUser';
import type { BlockUserRequest } from '../models/BlockUserRequest';
import type { FacebookUser } from '../models/FacebookUser';
import type { GetProfileResponse } from '../models/GetProfileResponse';
import type { GetPublicProfileResponse } from '../models/GetPublicProfileResponse';
import type { GetRatingStatsResponse } from '../models/GetRatingStatsResponse';
import type { GoogleUser } from '../models/GoogleUser';
import type { LoginRequest } from '../models/LoginRequest';
import type { ProfileDto } from '../models/ProfileDto';
import type { RegisterRequest } from '../models/RegisterRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { SearchUserDto } from '../models/SearchUserDto';
import type { SettingDto } from '../models/SettingDto';
import type { StringStringValuesKeyValuePair } from '../models/StringStringValuesKeyValuePair';
import type { UnblockUserRequest } from '../models/UnblockUserRequest';
import type { UpdateProfileRequest } from '../models/UpdateProfileRequest';
import type { UpdateSettingRequest } from '../models/UpdateSettingRequest';
import type { UpdateUserDisplaySettingRequest } from '../models/UpdateUserDisplaySettingRequest';
import type { UpdateUserNameRequest } from '../models/UpdateUserNameRequest';
import type { UserDto } from '../models/UserDto';
import type { UserScoreDto } from '../models/UserScoreDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @param requestBody 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static postApiV1UsersLogin(
requestBody?: LoginRequest,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public static getApiV1UsersGetCurrentUser(): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/GetCurrentUser',
        });
    }

    /**
     * @param requestBody 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static postApiV1UsersRegister(
requestBody?: RegisterRequest,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1UsersResetPassword(
requestBody?: ResetPasswordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/ResetPassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static postApiV1UsersLoginWithGoogle(
requestBody?: GoogleUser,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/LoginWithGoogle',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static postApiV1UsersLoginWithFacebook(
requestBody?: FacebookUser,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/LoginWithFacebook',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1UsersUpdateUsername(
requestBody?: UpdateUserNameRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/UpdateUsername',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static postApiV1UsersLoginWithApple(
requestBody?: AppleUser,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/LoginWithApple',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ProfileDto Success
     * @throws ApiError
     */
    public static postApiV1UsersCreateOrUpdateProfile(
requestBody?: UpdateProfileRequest,
): CancelablePromise<ProfileDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/CreateOrUpdateProfile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns SettingDto Success
     * @throws ApiError
     */
    public static postApiV1UsersCreateOrUpdateSetting(
requestBody?: UpdateSettingRequest,
): CancelablePromise<SettingDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/CreateOrUpdateSetting',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1UsersUpdateDisplaySetting(
requestBody?: UpdateUserDisplaySettingRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/UpdateDisplaySetting',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SettingDto Success
     * @throws ApiError
     */
    public static getApiV1UsersGetSetting(): CancelablePromise<SettingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/GetSetting',
        });
    }

    /**
     * @returns UserScoreDto Success
     * @throws ApiError
     */
    public static getApiV1UsersGetUserScore(): CancelablePromise<UserScoreDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/GetUserScore',
        });
    }

    /**
     * @returns GetProfileResponse Success
     * @throws ApiError
     */
    public static getApiV1UsersGetProfile(): CancelablePromise<GetProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/GetProfile',
        });
    }

    /**
     * @returns GetRatingStatsResponse Success
     * @throws ApiError
     */
    public static getApiV1UsersGetRatingStats(): CancelablePromise<GetRatingStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/GetRatingStats',
        });
    }

    /**
     * @param userId 
     * @returns GetPublicProfileResponse Success
     * @throws ApiError
     */
    public static getApiV1UsersGetPublicProfile(
userId?: string,
): CancelablePromise<GetPublicProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/GetPublicProfile',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @param key 
     * @param from 
     * @returns SearchUserDto Success
     * @throws ApiError
     */
    public static getApiV1UsersSearchUser(
key?: string,
from?: number,
): CancelablePromise<SearchUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/SearchUser',
            query: {
                'key': key,
                'from': from,
            },
        });
    }

    /**
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1UsersUploadProfilePicture(
formData?: {
form?: Array<StringStringValuesKeyValuePair>;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/UploadProfilePicture',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1UsersBlockUser(
requestBody?: BlockUserRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/BlockUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1UsersUnblockUser(
requestBody?: UnblockUserRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/UnblockUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getApiV1UsersIsUserBlocked(
userId?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/IsUserBlocked',
            query: {
                'userId': userId,
            },
        });
    }

}