/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTopicRequest } from '../models/CreateTopicRequest';
import type { TopicDto } from '../models/TopicDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TopicsService {

    /**
     * @returns TopicDto Success
     * @throws ApiError
     */
    public static getApiV1Topics(): CancelablePromise<Array<TopicDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Topics',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1Topics(
requestBody?: CreateTopicRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Topics',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}