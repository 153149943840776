import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {Container, Divider, List, ListItem, ListItemIcon, ListItemText, useMediaQuery} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import QuestionListItem from "../components/questionListItem";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import React, {useEffect, useState, useCallback} from "react";
import Button from "@mui/material/Button";

import { PrimaryTheme } from '../components/themes';
import Carousel, {ArrowProps} from "react-multi-carousel";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import IconButton from "@mui/material/IconButton";

import CalculateIcon from '@mui/icons-material/Calculate';
import ScienceIcon from '@mui/icons-material/Science';
import BiotechIcon from '@mui/icons-material/Biotech';
import ExploreIcon from '@mui/icons-material/Explore';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import DvrIcon from '@mui/icons-material/Dvr';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {
    AlertListDto,
    AlertsService, AnnouncementDto, AnnouncementsService,
    OpenAPI,
    OpenAPIConfig,
    QuestionPreviewDto,
    QuestionsService, TopicDto, TopicsService
} from "../services/autogen";
import {useAuth} from "../contexts/AuthContext";
import {Waypoint} from "react-waypoint";
import {Oval} from "react-loader-spinner";
import {Link} from "react-router-dom";
import {LoadingCircle} from "../components/LoadingCircle";

const drawerWidth = 240;

let theme = PrimaryTheme;

const responsive = {

    mobile: {
        breakpoint: { max: 500, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

type AnnouncementPanelItemProps = {
    children?: React.ReactNode;
    theme?: React.ReactNode;
    imgUri?: string | null;
}

function AnnouncementPanelItem( props: AnnouncementPanelItemProps ) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    let announcementWidth = 0.6 * window.innerWidth;
    return (
        <>
            {mobile &&
                <Card sx={{display: 'flex', m: 1, justifyContent: 'center'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        {/*
                        <CardContent sx={{padding: 1, border: '1px solid rgba(128, 128, 128, 0.5)', paddingTop:'5px', paddingBottom:'5px', paddingLeft:'25px', paddingRight:'25px',}} >
                            <Box sx={{ backgroundColor: theme.palette.background.default, paddingLeft: '2px', paddingRight: '2px'}}>
                                <Typography gutterBottom variant="subtitle1" component="div" align='center'
                                            sx={{fontSize: desktop ? "inherit" : "0.8rem", fontWeight: '600', }}>
                                    {props.children}
                                </Typography>
                            </Box>
                        </CardContent>
                        */}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '2px'
                        }}>
                            {/*
                            <CardContent>
                                <Typography variant="body2" sx={{fontSize: desktop ? "inherit" : "0.8rem"}}>
                                    The results of the 2021 Singapore-Cambridge General Certificate of Education Advanced Level
                                    (GCE A-Level) examination will be released on ...
                                </Typography>
                            </CardContent>
                            */}
                            <Box
                                sx={{display: 'flex', height: '100%', backgroundColor: 'aliceblue', alignItems: 'center',}}>
                                <CardMedia
                                    component="img"
                                    //height="140"
                                    image={props.imgUri || ""}
                                    alt="a-levelimg"
                                    sx={{
                                        resizeMode: "contain",
                                        maxWidth: '100%',
                                        width: 'auto',
                                        height: 'auto',
                                        border: '1px solid rgba(128, 128, 128, 0.5)'
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Card>
            }
            {desktop &&
                <Card sx={{maxWidth: 345, m: 1, backgroundColor: theme.palette.background.default}}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={props.imgUri || ""}
                        alt="a-levelimg"
                    />
                </Card>
            }
        </>
    );
}

const CustomRightArrow = ({ onClick, ...rest } : ArrowProps) => {
    return (
    <IconButton aria-label="upvote" onClick={() => onClick?.() } sx={{position:'absolute', right: 'calc(4% + 1px)' }} size="large">
        <ArrowCircleRightIcon sx={{opacity:'0.5'}} fontSize="inherit"/>
    </IconButton>);
};

const CustomLeftArrow = ({ onClick, ...rest } : ArrowProps) => {
    return (
        <IconButton aria-label="upvote" onClick={() => onClick?.() } sx={{position:'absolute', right: 'left(4% + 1px)' }} size="large">
            <ArrowCircleLeftIcon sx={{opacity:'0.5'}} fontSize="inherit"/>
        </IconButton>);
};




export function Home(){
    const mobile = !useMediaQuery('(min-width:600px)');
    const [isLoading, setIsLoading] = useState(true);
    const desktop = !mobile;
    const [questionList, setQuestionList] = useState<Array<QuestionPreviewDto> | null | undefined>([]);
    
    const [oldestQuestionTime, setOldestQuestionTime] = useState<string>();

    const setNewQuestionList = (list : Array<QuestionPreviewDto>) => {
        setQuestionList(list);
    }
    
    useEffect(()=>{
        async function innerFunc(){
            console.log("Token set to " + OpenAPI.TOKEN);
            let {data, hasMore} = await QuestionsService.getApiV1QuestionsGetQuestions(undefined, undefined,
                2147483647, 30, true);
            data = data?.filter(d => d.type !== "post" && !d.isAssigned);
            setQuestionList(data);

            // store the oldest question by updated time
            if (data) {
                let oldestTime = data[data?.length - 1].updatedAt;
                setOldestQuestionTime(oldestTime);
            }
            setIsLoading(false);
        }
        innerFunc();
    },[]);

    const {currentUser, setCurrentUser} = useAuth();

    const [announcementList, setAnnouncementList] = useState<Array<AnnouncementDto>| null>(null);

    const [topicList, setTopicList] = useState<Array<TopicDto>| null>(null);
    
    const [selectedTopic, setSelectedTopic] = useState<string | null | undefined>("all");

    const [isQuestionLoading, setIsQuestionLoading] = useState(false);

    const [hasMoreQuestion, setHasMoreQuestion] = useState(false);

    useEffect(()=> {
        if(currentUser){
            AnnouncementsService.getApiV1AnnouncementsGetAnnouncements()
                .then(val => {
                    setAnnouncementList(val);
                    //setLastAlertId(val?.data?[val.data.length-1].id)
                });
        }
    },[currentUser]);

    const [dist, setDist] = useState(0);

    useEffect(() => {
        // Your code here
        window.addEventListener('scroll', function() {
            console.log("print");

            setDist(window.scrollY);
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                console.log("you're at the bottom of the page");
               
            }
        });
    }, []);

    useEffect(()=> {
        if(currentUser){
            TopicsService.getApiV1Topics()
                .then(val => {
                    setTopicList(val);
                    //setLastAlertId(val?.data?[val.data.length-1].id)
                });
        }
    },[currentUser]);
    
    const loadMoreQuestion = useCallback(async (topicId: any) => {
        setIsQuestionLoading(true);
        console.log("Load more question");

        async function loadQuestion() {
            let prevQuestionTime = oldestQuestionTime;

            if (topicId != selectedTopic) {
                prevQuestionTime = "";
            }

            let {data, hasMore} = await QuestionsService.getApiV1QuestionsGetQuestions(prevQuestionTime, undefined,
                2147483647, 30, true);

            data = data?.filter(d => d.type !== "post");

            if (data && hasMore) {
                setHasMoreQuestion(hasMore);
            }


            // concatenate to the existing question list
            if (questionList && data) {
                let new_questionList = questionList.concat(data);
                if (topicId == selectedTopic) {
                    new_questionList = questionList.concat(data);
                }
                else {
                    new_questionList = data;
                }
                setQuestionList(new_questionList);
            }

            // store the oldest question by updated time
            if (data && data?.length > 1) {
                let oldestTime = data[data?.length - 1].updatedAt;
                setOldestQuestionTime(oldestTime);
            }
            setIsLoading(false);
        }

        async function loadQuestionWithTopic(topicId: string) {
            let prevQuestionTime = oldestQuestionTime;
            
            if (topicId != selectedTopic) {
                prevQuestionTime = "";
            }

            let {data, hasMore} = await QuestionsService.getApiV1QuestionsGetQuestions(prevQuestionTime, topicId,
                2147483647, 30, true);

            data = data?.filter(d => d.type !== "post");

            if (data && hasMore) {
                setHasMoreQuestion(hasMore);
            }


            // concatenate to the existing question list
            if (questionList && data) {
                let currentQuestionList = questionList;
                let new_questionList: Array<QuestionPreviewDto> = [];
                if (topicId == selectedTopic) {
                    new_questionList = questionList.concat(data);
                }
                else {
                    new_questionList = data;
                }
                setQuestionList(new_questionList);
            }

            // store the oldest question by updated time
            if (data && data?.length > 1) {
                let oldestTime = data[data?.length - 1].updatedAt;
                setOldestQuestionTime(oldestTime);
            }
            setIsLoading(false);
        }

        setIsLoading(true);

        if (!topicId || topicId == "all") {
            await loadQuestion();
        } else {
            await loadQuestionWithTopic(topicId);
        }

        setIsQuestionLoading(false);

    }, [questionList, oldestQuestionTime, selectedTopic]);
    
    function setTopic(topicId : string | null | undefined) {
        setQuestionList([]);
        setSelectedTopic(topicId);
        setOldestQuestionTime("");
        setHasMoreQuestion(false);
        if (topicId) {
            loadMoreQuestion(topicId);
        }
    }
    
    function mapTopic(topic: TopicDto) {
        if (topic.topicName == 'All') {
            return (
                <ListItem button key={topic.topicName} onClick={() => setTopic(topic.id)}>
                    <ListItemIcon>
                        <CalculateIcon/>
                    </ListItemIcon>
                    <ListItemText primary={topic.topicName}/>
                </ListItem>

            );
        }
        
        else if (topic.topicName == 'Maths') {
            return (
                <ListItem button key={topic.topicName} onClick={() => setTopic(topic.id)}>
                    <ListItemIcon>
                        <CalculateIcon/>
                    </ListItemIcon>
                    <ListItemText primary={topic.topicName}/>
                </ListItem>
                
            );
        }

        else if (topic.topicName == 'Chemistry') {
            return (
                <ListItem button key={topic.topicName} onClick={() => setTopic(topic.id)}>
                    <ListItemIcon>
                        <ScienceIcon/>
                    </ListItemIcon>
                    <ListItemText primary={topic.topicName}/>
                </ListItem>

            );
        }

        else if (topic.topicName == 'Science') {
            return (
                <ListItem button key={topic.topicName} onClick={() => setTopic(topic.id)}>
                    <ListItemIcon>
                        <BiotechIcon/>
                    </ListItemIcon>
                    <ListItemText primary={topic.topicName}/>
                </ListItem>

            );
        }

        else if (topic.topicName == 'Physics') {
            return (
                <ListItem button key={topic.topicName} onClick={() => setTopic(topic.id)}>
                    <ListItemIcon>
                        <ExploreIcon/>
                    </ListItemIcon>
                    <ListItemText primary={topic.topicName}/>
                </ListItem>

            );
        }

        /*else if (topic.topicName == 'Social Studies') {
            return (
                <ListItem button key={topic.topicName} onClick={() => setTopic(topic.id)}>
                    <ListItemIcon>
                        <TheaterComedyIcon/>
                    </ListItemIcon>
                    <ListItemText primary={topic.topicName}/>
                </ListItem>

            );
        }*/
    }
    
    return (
        <>
            <Box sx={{ width:'100%', position:'relative', display:'flex', overflow:'hidden' }}>
                {desktop &&
                    <Drawer
                        variant="persistent"
                        open={true}
                        PaperProps={{style: {position: 'absolute'}}}
                        BackdropProps={{style: {position: 'absolute'}}}
                        ModalProps={{
                            container: document.getElementById('drawer-container'),
                            style: {position: 'absolute'}
                        }}
                        anchor='left'
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                        }}
                    >
                        <Toolbar/>
                        <Box sx={{overflow: 'auto', maxWidth: window.innerWidth}}>
                            <List>
                                <ListItem button key={"All"} onClick={() => setTopic("all")}>
                                    <ListItemIcon>
                                        <DvrIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={"All"}/>
                                </ListItem>
       
                                {topicList && 
                                    topicList.map((topic, index) => mapTopic(topic) )
                                }
                            </List>
                            <Divider/>
                        </Box>
                    </Drawer>
                }
    
                
                <Box sx={{ maxHeight: 'calc(100vh - 0px)',   overflowY: 'scroll', minWidth: '50vw' }}>
                    <Box component="main" sx={{ flexGrow: 1, p: 3, padding:'0px', maxWidth: '750px', /* overflowY: 'scroll' */  display: desktop ? 'flex' : 'block', justifyContent: desktop ? 'center' : 'none'}}>
    
                        {mobile &&
                            <Box sx={{ maxWidth: window.innerWidth, marginTop: '60px'}}>
                                {announcementList &&
                                    <Carousel
                                        swipeable={true}
                                        //draggable={true}
                                        //showDots={true}
        
                                        responsive={responsive}
                                        partialVisible={false}
                                        ssr={true} // means to render carousel on server-side.
                                        infinite={true}
                                        //autoPlay={false}
                                        //autoPlay={this.props.deviceType !== "mobile" ? true : false}
                                        shouldResetAutoplay={false}
                                        //autoPlaySpeed={1000}
                                        keyBoardControl={true}
                                        //customTransition="all .5"
                                        //transitionDuration={500}
                                        containerClass="carousel-container"
                                        //removeArrowOnDeviceType={["tablet", "mobile"]}
                                        //deviceType={this.props.deviceType}
                                        dotListClass="custom-dot-list-style"
                                        itemClass="carousel-item-padding-40-px"
                                        customRightArrow={<CustomRightArrow/>}
                                        customLeftArrow={<CustomLeftArrow/>}
                                    >
                                        {
                                            announcementList.map((announcement) => {
                                            if (announcement) {
                                                return (
                                                    <AnnouncementPanelItem key={announcement.announcementId}
                                                                           imgUri={announcement.srcImage}/>
                                                );
                                            }
                                        })}
        
                                    </Carousel>
                                }
                            </Box>
                                
                        }
                        
                        <Box sx={{marginTop: desktop ? '70px' : '0px', maxWidth: window.innerWidth, /*overflow: 'scroll' display:'flex',flexDirection:'column', justifyContent:'center',*/ alignItems:'center', width: desktop ? '90%' : '100%'}}>
                            
                            {questionList && questionList.length >= 1 &&
                                questionList?.slice(0,questionList.length-1).map(qli => <QuestionListItem key={qli.id} item={qli} qnList={questionList} setQnList={setNewQuestionList}/>)
                            }
                            {hasMoreQuestion &&
                                !isLoading && <Waypoint onEnter={() => loadMoreQuestion(selectedTopic)} /> 
                            }
                            {
                                questionList?.slice(questionList.length-1,questionList.length).map(qli => <QuestionListItem key={qli.id} item={qli} qnList={questionList} setQnList={setNewQuestionList}/>)
                            }
                            { isQuestionLoading && isLoading && 
                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', marginBottom: '20px', width: '100%'}}>
                                    <LoadingCircle></LoadingCircle>
                                </Box>
    
                            }
                        </Box>
                    </Box>
                    
                </Box>
                
                {desktop &&
                    <Box sx={{maxHeight:'100vh'}}>
                        <Drawer
                            variant="persistent"
                            open={true}
                            PaperProps={{style: {position: 'absolute', paddingLeft: '10px',}}}
                            BackdropProps={{style: {position: 'absolute'}}}
                            ModalProps={{
                                container: document.getElementById('drawer-container'),
                                style: {position: 'absolute'}
                            }}
                            anchor="right"
                            sx={{
                                width: drawerWidth,
                                minHeight: '100vw',
                                flexShrink: 0,
                                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                            }}
                        >
                            <Toolbar/>
                            {announcementList && announcementList.map((announcement) => {
                                if (announcement) {
                                    return (
                                        <AnnouncementPanelItem imgUri={announcement.srcImage}/>
                                    );
                                }
                            })}
                            
                        </Drawer>
                    </Box>
                }

            
            </Box>
        </>
        );
}