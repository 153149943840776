/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApproveTransactionCancellationRequest } from '../models/ApproveTransactionCancellationRequest';
import type { PayAnswerRequest } from '../models/PayAnswerRequest';
import type { PayTenderRequest } from '../models/PayTenderRequest';
import type { RejectTransactionCancellationRequest } from '../models/RejectTransactionCancellationRequest';
import type { TerminateTransactionRequest } from '../models/TerminateTransactionRequest';
import type { TopUpRequest } from '../models/TopUpRequest';
import type { TransactionListDto } from '../models/TransactionListDto';
import type { WalletDto } from '../models/WalletDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentsService {

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1PaymentsCreateTransactionCancellation(
requestBody?: TerminateTransactionRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Payments/CreateTransactionCancellation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1PaymentsApproveTransactionCancellation(
requestBody?: ApproveTransactionCancellationRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Payments/ApproveTransactionCancellation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1PaymentsRejectTransactionCancellation(
requestBody?: RejectTransactionCancellationRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Payments/RejectTransactionCancellation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1PaymentsTopUp(
requestBody?: TopUpRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Payments/TopUp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1PaymentsPayTender(
requestBody?: PayTenderRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Payments/PayTender',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1PaymentsPayAnswer(
requestBody?: PayAnswerRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Payments/PayAnswer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param lastId 
     * @returns TransactionListDto Success
     * @throws ApiError
     */
    public static getApiV1PaymentsGetTransactionsForUser(
lastId?: string,
): CancelablePromise<TransactionListDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Payments/GetTransactionsForUser',
            query: {
                'lastId': lastId,
            },
        });
    }

    /**
     * @returns WalletDto Success
     * @throws ApiError
     */
    public static getApiV1PaymentsGetWalletForUser(): CancelablePromise<WalletDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Payments/GetWalletForUser',
        });
    }

}